import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
          },
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'user-profile',
            loadChildren: () => import('./pages/pages/auth/user-profile/user-profile.module').then(m => m.UserProfileModule),
          },
          {
            path: 'organization',
            loadChildren: () => import('./pages/pages/application-settings/organization/organization.module').then(m => m.OrganizationModule),
          }, 

          {
            path: 'vendor',
            loadChildren: () => import('./pages/pages/vender/vendor-entry/vendor-entry.module').then(m => m.VendorEntryModule),
          }, 
          {
            path: 'vendor-view',
            loadChildren: () => import('./pages/pages/vender/vendor-view/vendor-view.module').then(m => m.VendorViewModule),
          }, 

          {
            path: 'access-control',
            loadChildren: () => import('./pages/pages/application-settings/rights/roleandrights.module').then(m => m.RoleandrightsModule),
          }, 
          {
            path: 'product-entry',
            loadChildren: () => import('./pages/pages/product/product/add-new.module').then(m => m.AddNewModule),
          }, 
          {
            path: 'product-view',
            loadChildren: () => import('./pages/pages/product/view-product/view-product.module').then(m => m.ViewProductModule),
          }, 
          {
            path: 'import-export',
            loadChildren: () => import('./pages/pages/product/upload/product-upload.module').then(m => m.ProductUploadModule),
          }, 
          {
            path: 'product-rate',
            loadChildren: () => import('./pages/pages/product/rate-update/product-rate-new.module').then(m => m.ProductRateNewModule),
          },           
          {
            path: 'payment-out',
            loadChildren: () => import('./pages/pages/payment-out/payment.module').then(m => m.PaymentModule),
          }, 

          {
            path: 'purchase-bill',
            loadChildren: () => import('./pages/pages/purchase/purchase/purchase-bill.module').then(m => m.PurchaseBillModule),
          }, 
          {
            path: 'purchase-bill-view',
            loadChildren: () => import('./pages/pages/purchase/purchase/view/purchaseview.module').then(m => m.PurchaseviewModule),
          }, 
          {
            path: 'direct-stock',
            loadChildren: () => import('./pages/pages/stock/direct-stock-c1/direct-stock.module').then(m => m.DirectStockModule),
          }, 
          {
            path: 'stock-adjustment',
            loadChildren: () => import('./pages/pages/stock/stock-adjustment/stock-adjustment.module').then(m => m.StockAdjustmentModule),
          }, 
          
          {
            path: 'sale-invoice',
            loadChildren: () => import('./pages/pages/sale-invoice/sale-invoice.module').then(m => m.SaleInvoiceModule),
          }, 
          {
            path: 'sale-invoice-f1',
            loadChildren: () => import('./pages/pages/sale-invoice-f1/sale-invoice.module').then(m => m.SaleInvoiceModule),
          }, 
          {
            path: 'payment-in',
            loadChildren: () => import('./pages/pages/payment-in/receipt.module').then(m => m.ReceiptModule),
          }, 
           {
            path: 'sale-invoice-view',
            loadChildren: () => import('./pages/pages/sale-invoice/view/sales-invoice-view.module').then(m => m.SalesInvoiceViewModule),
          },    
          
          {
            path: 'sale-invoice-view-f1',
            loadChildren: () => import('./pages/pages/sale-invoice/view/sales-invoice-view.module').then(m => m.SalesInvoiceViewModule),
          }, 

          {
            path: 'sales-return',
            loadChildren: () => import('./pages/pages/sales-return/sales-return.module').then(m => m.SalesReturnModule),
          }, 

          {
            path: 'sales-return-view',
            loadChildren: () => import('./pages/pages/sales-return/view/sales-return-view.module').then(m => m.SalesReturnViewModule),
          },    
          
          {
            path: 'dc-entry-multi',
            loadChildren: () => import('./pages/pages/delivery-challan/multi-entry/dc-entry.module').then(m => m.DcEntryModule),
          }, 
          {
            path: 'dc-entry-multi-view',
            loadChildren: () => import('./pages/pages/delivery-challan/multi-entry/view/dc-entry-view.module').then(m => m.DcEntryViewModule),
          }, 
          
          {
            path: 'dc-entry-single',
            loadChildren: () => import('./pages/pages/delivery-challan/single-entry/c-1/dc-entry.module').then(m => m.DcEntryModule),
          }, 
          {
            path: 'dc-entry-single-view',
            loadChildren: () => import('./pages/pages/delivery-challan/single-entry/c-1/view/dc-entry-view.module').then(m => m.DcEntryViewModule),
          }, 
          
          {
            path: 'order-single',
            loadChildren: () => import('./pages/pages/order-entry/order-c1/order.module').then(m => m.OrderModule),
          },           
          {
            path: 'order-single-view',
            loadChildren: () => import('./pages/pages/order-entry/order-c1/view/order-view.module').then(m => m.OrderViewModule),
          },  
          {
            path: 'order-single-ledger',
            loadChildren: () => import('./pages/pages/order-entry/order-c1/order-ledger/order-ledger.module').then(m => m.OrderLedgerModule),
          },  


          {
            path: 'customer-advance',
            loadChildren: () => import('./pages/pages/customer-advance/receipt-c2/receipt.module').then(m => m.ReceiptModule),
          },  
          {
            path: 'journal-entry',
            loadChildren: () => import('./pages/pages/journal-entry/journal-entry.module').then(m => m.JournalEntryModule),
          },  
          
          {
            path: 'manual-payment',
            loadChildren: () => import('./pages/pages/manual-payment/manual-payment.module').then(m => m.ManualPaymentModule),
          },  

          {
            path: 'return-entry',
            loadChildren: () => import('./pages/pages/return-entry/rtn-entry.module').then(m => m.RtnEntryModule),
          },  
    
          
          {
            path: 'expense',
            loadChildren: () => import('./pages/pages/expense/expense.module').then(m => m.ExpenseModule),
          }, 

          {
            path: 'sales-order-multi',
            loadChildren: () => import('./pages/pages/sales-order/multi-entry/sales-order.module').then(m => m.SalesOrderModule),
          }, 

          {
            path: 'appointment-view',
            loadChildren: () => import('./pages/pages/appointment/appointment-view.module').then(m => m.AppointmentViewModule),
          }, 
          

          {
            path: 'lead-generation',
            loadChildren: () => import('./pages/pages/lead-entry/lead-generation.module').then(m => m.LeadGenerationModule),
          },

          {
            path: 'employee',
            loadChildren: () => import('./pages/pages/master/employee/employee.module').then(m => m.EmployeeModule)
          },
          {
            path: 'attendance-entry',
            loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendanceModule),
          },     
          {
            path: 'bank-master',
            loadChildren: () => import('./pages/pages/bank-master/bank-account.module').then(m => m.BankAccountModule),
          },
          
          {
            path: 'bank-transation',
            loadChildren: () => import('./pages/pages/bank-transaction/bank-account.module').then(m => m.BankAccountModule),
          },
          {
            path: 'department',
            loadChildren: () => import('./pages/pages/master/department/department.module').then(m => m.DepartmentModule),
          },
          {
            path: 'designation',
            loadChildren: () => import('./pages/pages/master/designation/designation.module').then(m => m.DesignationModule),
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },

     {
      path: 'reports',
      children: [
        {
          path: 'tag-stock-report',
          loadChildren: () => import('./pages/pages/reports/stock-reports/tag-stock-report/tag-stock-report.module').then(m => m.TagStockReportModule),
        }, 
        {
          path: 'current-stock-report',
          loadChildren: () => import('./pages/pages/reports/stock-reports/current-stock-report/current-stock.module').then(m => m.CurrentStockModule),
        }, 
        
        {
          path: 'Product-Wise-Purchase-Report',
          loadChildren: () => import('./pages/pages/reports/purchase-reports/productwise-purchase/productwise-purchase.module').then(m => m.ProductwisePurchaseModule),
        }, 
        {
          path: 'Product-Wise-sales-Report',
          loadChildren: () => import('./pages/pages/reports/sales-reports/productwise-sales/productwise-sales.module').then(m => m.ProductwiseSalesModule),
        }, 
        {
          path: 'credit-sales-summary',
          loadChildren: () => import('./pages/pages/reports/sales-reports/creditsummary-sales/creditsummary-sales.module').then(m => m.CreditsummarySalesModule),
        },         

         {
          path: 'day-book-report',
          loadChildren: () => import('./pages/pages/reports/mis-report/dayBook/dayBook.module').then(m => m.DaybookModule),
        },  
        
           {
          path: 'manual-rec-pay-report',
          loadChildren: () => import('./pages/pages/reports/manual-recpay/dayBook.module').then(m => m.DaybookModule),
        },  
        //GST REPORTS
        {
          path: 'gstr1-report',
          loadChildren: () => import('./pages/pages/reports/gst-report/gstr1/gst-sales.module').then(m => m.GstSalesModule),
        },      
         {
          path: 'gstr1-detail-report',
          loadChildren: () => import('./pages/pages/reports/gst-report/gstr1-detail/gst-sales.module').then(m => m.GstSalesModule),
        },             
        
      ]
     },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: QuicklinkStrategy,
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        useHash: true,
    })],
    exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
